<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      backgroundImage: "",
    };
  },
  methods: {
    handleRemove() {
      localStorage.removeItem("finishedLottery");
      alert("清除成功");
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

.van-cell::after {
  display: none !important;
}
</style>
