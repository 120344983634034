import VueRouter from 'vue-router';

export default new VueRouter({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'card',
            component: resolve => require(['../view/card.vue'], resolve),
        },
    ],
});