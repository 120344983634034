import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import router from './router';
import {Uploader, Field, Button} from 'vant';
import 'vant/lib/index.css';

Vue.use(Uploader);
Vue.use(Field);
Vue.use(Button);

Vue.config.productionTip = false;
// 获取原型对象上的push函数 修改原型对象中的push方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
